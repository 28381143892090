<template>
  <h2>网上报馆列表</h2>
  <!-- <a-list
    class="demo-loadmore-list"
    :loading="loading"
    item-layout="horizontal"
    :data-source="dataList"
    position="top"
    :pagination="pagination"
  >
    <template #renderItem="{ item }">
      <a-list-item>
        <template #actions>
          <a-button size="small" @click="toSignUp(item)" :disabled="item.status!=1?false:item.roleStatus==1?false:true">我要报馆</a-button>
        </template>
        <a-list-item-meta
          :description="`所在城市：${item.city} 展馆：${item.exhibition_centre?item.exhibition_centre:'未知'} 开展时间：${item.stime} ${item.etime ? '截止时间：'+item.etime : ''}`"
        >
          <template #title>
            <span>{{item.p_name}}</span>
          </template>
        </a-list-item-meta>
      </a-list-item>
    </template>
  </a-list> -->

  <a-list class="list" :grid="{ gutter: 30, xs: 1, sm: 1, md: 1, lg: 2, xl: 3, xxl: 4 }" :data-source="dataList">
    <template #renderItem="{ item }">
      <a-list-item>
        <div class="card">
          <!-- {{ item.logo_url }} -->
          <img :src="item.logo_url" alt="">
          <h3 class="kele-ellipsis-2">{{ item.p_name }}</h3>
          <div class="info">
            <p>展馆地址：{{ item.exhibition_centre }}</p>
            <p>展馆开展时间：{{ item.stime }}</p>
            <p>展馆截止时间：{{ item.etime }}</p>
          </div>
          <a-button class="card-button" type="primary" @click="toSignUp(item)" :disabled="item.status!=1?false:item.roleStatus==1?false:true">我要报馆</a-button>
        </div>
      </a-list-item>
    </template>
  </a-list>
  <a-pagination class="pagination" v-model:current="paginationObj.current" :pageSize="paginationObj.pageSize" :total="paginationObj.total" @change="paginationChange" show-less-items style="text-align: center;" />
</template>
<script>

import { defineComponent, onMounted, ref, getCurrentInstance, reactive } from 'vue'
import { Modal } from 'ant-design-vue'
import { initProjectList } from '../../api'

export default defineComponent({
  setup() {
    const { proxy } = getCurrentInstance()
    const dataList = ref([])
    const paginationObj = reactive({
      current: 1,
      pageSize: 12,
      total: 0
    })
    // const pagination = {
      const paginationChange = (page, pageSize) => {
        console.log(page,pageSize);
        initProjectListFun(page, pageSize)
      }
      
      // showSizeChanger: true
    // }
    const initProjectListFun = async (page = 1, pageSize = paginationObj.pageSize) => {
      window.sessionStorage.removeItem('project_id')
      window.sessionStorage.removeItem('projectFlag')
      window.sessionStorage.removeItem('id')
      window.sessionStorage.removeItem('etime')
      window.sessionStorage.removeItem('create_time')
      window.sessionStorage.removeItem('overdue')
      window.sessionStorage.removeItem('overdue_proportion')
      const userId = window.sessionStorage.getItem('user-id')
      const res = await initProjectList(userId, page, pageSize)
      dataList.value = res.data.list
      paginationObj.total = res.data.total
    }

    onMounted(initProjectListFun)
    const toSignUp = (item) => {
      console.log(item, item.p_name)
      const date = new Date(item.etime)
      const setTime = date.getTime(date)
      const currentTime = Date.parse(new Date())
      console.log()
      window.sessionStorage.setItem('project_id', item.id)
      if (item.overdue && currentTime >= setTime) {
        warning(item)
        return
      }
      proxy.$root.$router.push({ name: 'newNewspaper', params: { pid: item.id, name: item.p_name } })
    }
    const warning = (item) => {
      Modal.warning({
        title: () => '逾期提醒',
        content: () => `您好，您未按指定时间内报馆,现在报馆将收取${item.overdue_proportion}%额外费用`,
        onOk() {
          window.sessionStorage.setItem('overdue', 1)
          window.sessionStorage.setItem('overdue_proportion', item.overdue_proportion)
          proxy.$root.$router.push({ name: 'newNewspaper', params: { pid: item.id, name: item.p_name } })
        }
      })
    }
    return {
      // pagination,
      loading: false,
      loadingMore: false,
      dataList,
      paginationObj,
      loadMore: false,
      toSignUp,
      paginationChange
    }
  }
})
</script>
<style scoped>
h2 {
  padding-bottom: 20px;
  font-size: 24px;
  font-weight: bold;
}
.list{
  margin: auto;
}
.card {
  margin-bottom: 30px;
  padding: 20px;
  box-shadow: 0 0 10px #ccc;
  text-align: center;
  border-radius: 10px;
  cursor: pointer;
  transition: all .5s;
}
.card:hover{
  box-shadow: 3px 3px 15px #999;
}

.card img {
  width: 100%;
  height: 50px;
  object-fit: contain;
  max-width: max-content;
  user-select: none;
}

.card h3 {
  height: 43px;
  margin-bottom: 5px;
  /* padding-bottom: 20px; */
  font-size: 15px;
  font-weight: bold;
}

.info {
  margin: auto;
  width: max-content;
  text-align: left;
  user-select: none;
}

.info p {
  font-size: 12px;
  margin-bottom: 0px;
}

.card-button {
  padding: 0 20px;
  margin-top: 20px;
  border-radius: 10px;
  height: 28px;
  background-color: #d6171f;
  border-color: #d6171f;
}
.card-button:hover{
  background-color: #ecac1b;
  border-color: #ecac1b;
}
.ant-btn-primary[disabled]{
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}
.pagination{
  margin-top: 30px;
}
@media (min-width: 768px) {
  .list{
    max-width: 800px;
  }
}
@media (min-width: 992px) {
  .list{
    max-width: 1000px;
  }
}
@media (min-width: 1200px) {
  .list{
    max-width: 1300px;
  }
}
@media (min-width: 1600px) {
  .list{
    max-width: 1700px;
  }
}
</style>
<!-- <style scoped>
.demo-loadmore-list {
  min-height: 350px;
}
</style> -->
